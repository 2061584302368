import React, { useEffect, useState } from 'react';
// import Footer from './Footer';
import Goatlogo from '../asset/images/BANI.png';
// import Bani from "../asset/images/BANI.png"
import { ImSpinner, ImTelegram } from 'react-icons/im';
import { makeApiRequest } from '../services/commonAxiosService';
import { IoCloseSharp } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import { BsCheckLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Bani from '../asset/images/propelex.png';
import { useForm } from 'react-hook-form';
import { MdOutlineCancel } from 'react-icons/md';
import Banis from '../asset/images/BANI.png';
import Getx from '../asset/images/getx.png';
import { Spinner } from 'react-bootstrap';

function Thirdtab({ data }) {
  const navigate = useNavigate();
  let chatId;
  if (data) {
    chatId = data;
  } else {
    chatId = localStorage.getItem('userId');
  }
  const [taskData, setTaskData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rewardAmt, setRewardAmt] = useState({});
  const [loading, setLoading] = useState({});
  const [displayGetxToken, setDisplayGetxToken] = useState(false);
  const [couponGetxNumber, setCouponGetxNumber] = useState('');

  useEffect(() => {
    tabBasedTasks('Bani');
    getCouponReward();
  }, []);

  const getCouponReward = async () => {
    try {
      setLoader(true);
      let params = {
        url: 'get-coupon-reward',
        method: 'GET',
      };
      let response = await makeApiRequest(params);

      if (response.status) {
        setRewardAmt(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(true);
    }
  };

  const tabBasedTasks = async (Tasktype) => {
    try {
      setLoader(true);
      let tasktype = 'Bani';
      if (Tasktype === 'Partners') {
        tasktype = 'Partners';
      } else {
        tasktype = 'Bani';
      }
      let paramsData = {
        userId: chatId,
        taskType: tasktype,
      };
      let params = {
        url: 'view-tasks',
        method: 'POST',
        data: paramsData,
      };
      let response = await makeApiRequest(params);

      if (response.status) {
        setTaskData(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(true);
    }
  };

  const doTaskSubmission = async (taskId, taskType, taskUrl) => {
    try {
      window.open(taskUrl);
      setLoading((prev) => ({ ...prev, [taskId]: true }));
      let paramsData = {
        userId: chatId,
        taksId: taskId,
      };
      let payLoad = {
        url: 'do-task',
        method: 'POST',
        data: paramsData,
      };
      setTimeout(async () => {
        let taskRes = await makeApiRequest(payLoad);
        if (taskRes.status) {
          toast.success(taskRes.message, { theme: 'dark' });
          tabBasedTasks(taskType);
          setLoading((prev) => ({ ...prev, [taskId]: false }));
        } else {
          setLoading((prev) => ({ ...prev, [taskId]: false }));
        }
      }, 8000);
    } catch (error) {
      setLoading((prev) => ({ ...prev, [taskId]: false }));
      console.log('doTaskSubmission error', error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let payLoad = {
      chatId: chatId,
      couponCode: data.couponCode,
    };
    let params = {
      method: 'post',
      url: 'submit-coupon',
      data: payLoad,
    };
    let res = await makeApiRequest(params);

    if (res.status) {
      toast.success(res.message, { theme: 'dark' });
      openModel();
    } else {
      toast.error(res.message, { theme: 'dark' });
    }
  };

  const onGetxSubmit = async (data) => {
    try {
      let payLoad = {
        chatId: chatId,
        couponCode: data.couponCode,
      };
      let params = {
        method: 'post',
        url: 'submit-getx-coupon',
        data: payLoad,
      };
      let res = await makeApiRequest(params);

      if (res.status) {
        toast.success(res.message, { theme: 'dark' });
        openGetxModel(); // Close modal and refresh status
      } else {
        toast.error(res.message, { theme: 'dark' });
      }
    } catch (error) {
      toast.error('Failed to submit GetX coupon', { theme: 'dark' });
      console.error('GetX submission error:', error);
    }
  };

  const [displayToken, setDisplayToken] = useState(false);
  const [couponNumber, setCouponNumber] = useState('');

  const openModel = async () => {
    try {
      let payLoad = {
        chatId: chatId,
      };
      let params = {
        method: 'post',
        url: 'verify-coupon',
        data: payLoad,
      };
      let res = await makeApiRequest(params);
      if (res.status == true) {
        setDisplayToken(true);
      } else {
        if (res.data) {
          setCouponNumber(res.data.coupon_no);
        }
        setDisplayToken(false);
      }
    } catch (error) {
      setDisplayToken(false);
    }
  };
  // Add function to check GetX claim status
  const openGetxModel = async () => {
    try {
      let payLoad = {
        chatId: chatId,
      };
      let params = {
        method: 'post',
        url: 'getx-claim-status',
        data: payLoad,
      };
      let res = await makeApiRequest(params);
      if (res.status === true) {
        setDisplayGetxToken(true);
      } else {
        if (res.data) {
          setCouponGetxNumber(res.data.coupon_no);
        }
        setDisplayGetxToken(false);
      }
    } catch (error) {
      setDisplayGetxToken(false);
      console.error('GetX verification error:', error);
    }
  };

  return (
    <div className="goat-secondtabs-01">
      <ToastContainer />
      <div className="container">
        <div className="row min-vh-100">
          <div className="col-lg-12">
            <div className="">
              <h2 className="text-white py-4 text-center" style={{ fontSize: '45px' }}>
                Missions
              </h2>
              <div className="goat-ranking-whl goat-ranking-whl-alter">
                <div>
                  <ul
                    class="nav nav-pills mb-3 d-flex justify-content-around"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        onClick={() => tabBasedTasks('Bani')}
                        class="nav-link active  fw-bold goat-total-new-heading"
                        id="pills-home-goats-tab"
                        style={{ color: 'grey' }}
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home-goats"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        $BANI
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        onClick={() => tabBasedTasks('Partners')}
                        class="nav-link fw-bold goat-total-new-heading"
                        id="pills-profile-partners-tab"
                        style={{ color: 'grey' }}
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile-partners"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        PARTNERS
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-home-goats"
                      role="tabpanel"
                      aria-labelledby="pills-home-goats-tab"
                    >
                      {loader == true ? (
                        <>
                          <div class=" position-absolute bani-loader-ctns-01" role="status">
                            <span class="visually-hidden">Loading...</span>
                            <img src={Banis} className="bani-loader-img" />
                            <p className="mt-4 ms-1 text-white" style={{ fontSize: '15px' }}>
                              <span class="dot text-white me-1">12</span>
                              <span class="dot me-1">12</span>
                              <span class="dot me-1">12</span>
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <p className="goat-special-mission fw-bold">$Bani</p>
                            <div className=" px-2 my-3 bg-dark goat-posts py-3">
                              {taskData.length > 0 ? (
                                taskData.map((data) => {
                                  return (
                                    <>
                                      <div className="goat-react-posts posts-devopos-001 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between ">
                                          <div className="mission-alt-img-tabs">
                                            <img
                                              alt=""
                                              className="alt-goat-tabs"
                                              src={data.taskImgUrl}
                                            />
                                          </div>
                                          <div>
                                            <p className="mb-auto">
                                              <span className="text-white mb-5 goats-earn-01 fw-bold">
                                                {data.taskTitle}
                                              </span>
                                              <br />
                                              <span
                                                className=" fw-bold total-small-size-ctn"
                                                style={{ color: 'grey' }}
                                              >
                                                +{data.taskReward} $BANI
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <div>
                                          {data.userTaskData.length ? (
                                            <>
                                              <span className="goat-check-01">
                                                <BsCheckLg
                                                  className="text-white"
                                                  style={{ fontSize: '20px' }}
                                                />
                                              </span>
                                            </>
                                          ) : loading[data._id] == true ? (
                                            <>
                                              <button className="goats-do-btn">
                                                <Spinner animation="border" size="sm" />
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                onClick={() =>
                                                  doTaskSubmission(
                                                    data._id,
                                                    data.taskType,
                                                    data.taskDesc
                                                  )
                                                }
                                                className="goats-do-btn"
                                              >
                                                DO
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  <h2>There is no tasks</h2>
                                </>
                              )}
                            </div>
                            <div className="my-4"></div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-profile-partners"
                      role="tabpanel"
                      aria-labelledby="pills-profile-partners-tab"
                    >
                      <div>
                        {loader == true ? (
                          <>
                            <div class=" position-absolute bani-loader-ctns-01" role="status">
                              <span class="visually-hidden">Loading...</span>
                              <img src={Banis} className="bani-loader-img" />
                              <p className="mt-4 ms-1 text-white" style={{ fontSize: '15px' }}>
                                <span class="dot text-white me-1">12</span>
                                <span class="dot me-1">12</span>
                                <span class="dot me-1">12</span>
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <p className="goat-wcoin-head fw-bold">Partners</p>
                              {/* Propelx Claim */}
                              <div className="d-flex justify-content-between align-items-center px-1 bg-dark new-partner-tokens py-3">
                                <div className="d-flex justify-content-around align-items-center">
                                  <img src={Bani} className="alt-propelex-images ps-1" />
                                  <div>
                                    <p className="mb-auto">
                                      <span className="text-white mb-5 goats-earn-01 fw-bold">
                                        Daily Propelx Claim
                                      </span>
                                      <br />
                                      <span
                                        className=" fw-bold total-small-size-ctn"
                                        style={{ color: 'grey' }}
                                      >
                                        +{rewardAmt && rewardAmt.couponCode_Reward} BANI
                                      </span>
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <span
                                    className="goat-check-01-1 text-white me-2"
                                    data-bs-toggle="modal"
                                    onClick={() => openModel()}
                                    data-bs-target="#staticBackdrop"
                                  >
                                    Enter code
                                  </span>
                                </div>
                              </div>

                              {/* Getx Claim */}
                              <div className="d-flex justify-content-between align-items-center px-1 bg-dark new-partner-tokens py-3 mt-3">
                                <div className="d-flex justify-content-around align-items-center">
                                  <img src={Getx} className="alt-propelex-images ps-1" />
                                  <div>
                                    <p className="mb-auto">
                                      <span className="text-white mb-5 goats-earn-01 fw-bold">
                                        Daily Getx Claim
                                      </span>
                                      <br />
                                      <span
                                        className="fw-bold total-small-size-ctn"
                                        style={{ color: 'grey' }}
                                      >
                                        +{rewardAmt && rewardAmt.couponCode_Reward} BANI
                                      </span>
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <span
                                    className="goat-check-01-1 text-white me-2"
                                    data-bs-toggle="modal"
                                    onClick={() => openGetxModel()}
                                    data-bs-target="#staticBackdropGetx"
                                  >
                                    Enter code
                                  </span>
                                </div>
                              </div>

                              <button className="d-none"></button>

                              {/* Propelx Modal */}
                              <div
                                class="modal fade"
                                id="staticBackdrop"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabindex="-1"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog modal-dialog-centered">
                                  <div
                                    class="modal-content"
                                    style={{
                                      background: '#262a2e',
                                      borderRadius: '8px',
                                    }}
                                  >
                                    <div class="modal-header">
                                      <button
                                        type="button"
                                        class="btn-close btn-close-neww"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="d-flex justify-content-end py-2 px-2"></div>
                                    {displayToken ? (
                                      <form onSubmit={handleSubmit(onSubmit)}>
                                        <div class="modal-body input-box-code text-center">
                                          <p className="text-white mt-2 mb-3">
                                            Enter the Unique daily code <br />
                                            on propelx
                                          </p>
                                          <input
                                            type="text"
                                            className=" py-2 px-2 border-0"
                                            style={{
                                              background: 'black',
                                              color: '#fff',
                                              marginBottom: '10px',
                                            }}
                                            placeholder="Enter the Code"
                                            {...register('couponCode', {
                                              required: true,
                                              minLength: 3,
                                            })}
                                          />
                                          <br />
                                          {errors.couponCode &&
                                            errors.couponCode.type === 'required' && (
                                              <span style={{ color: 'red' }} class="error-msg">
                                                This field is required
                                              </span>
                                            )}
                                          {errors.couponCode &&
                                            errors.couponCode.type === 'minLength' && (
                                              <span style={{ color: 'red' }} class="error-msg">
                                                This field required min 3 charcters
                                              </span>
                                            )}
                                        </div>

                                        <div
                                          class="modal-footer input-box-code justify-content-center mt-0"
                                          style={{ borderTop: 'none' }}
                                        >
                                          <button
                                            class="btn btn-secondary px-5 mt-0"
                                            style={{
                                              background: '#297efa',
                                              border: 'none',
                                            }}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </form>
                                    ) : (
                                      <>
                                        <p
                                          className="text-white text-center mb-3"
                                          style={{ fontSize: '20px' }}
                                        >
                                          You've successfully redeemed your today's reward!
                                        </p>
                                        <p className="text-white text-center err-first-ctn">
                                          {couponNumber != '' ? couponNumber : ''}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Getx Modal */}
                              <div
                                className="modal fade"
                                id="staticBackdropGetx"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabindex="-1"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div
                                    className="modal-content"
                                    style={{
                                      background: '#262a2e',
                                      borderRadius: '8px',
                                    }}
                                  >
                                    <div className="modal-header">
                                      <button
                                        type="button"
                                        className="btn-close btn-close-neww"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>

                                    {displayGetxToken ? (
                                      <form onSubmit={handleSubmit(onGetxSubmit)}>
                                        <div className="modal-body input-box-code text-center">
                                          <p className="text-white mt-2 mb-3">
                                            Enter the Unique daily code <br />
                                            on GetX
                                          </p>
                                          <input
                                            type="text"
                                            className="py-2 px-2 border-0"
                                            style={{
                                              background: 'black',
                                              color: '#fff',
                                              marginBottom: '10px',
                                            }}
                                            placeholder="Enter the Code"
                                            {...register('couponCode', {
                                              required: true,
                                              minLength: 3,
                                            })}
                                          />
                                          <br />
                                          {errors.couponCode &&
                                            errors.couponCode.type === 'required' && (
                                              <span style={{ color: 'red' }} className="error-msg">
                                                This field is required
                                              </span>
                                            )}
                                          {errors.couponCode &&
                                            errors.couponCode.type === 'minLength' && (
                                              <span style={{ color: 'red' }} className="error-msg">
                                                This field required min 3 characters
                                              </span>
                                            )}
                                        </div>

                                        <div
                                          className="modal-footer input-box-code justify-content-center mt-0"
                                          style={{ borderTop: 'none' }}
                                        >
                                          <button
                                            className="btn btn-secondary px-5 mt-0"
                                            style={{
                                              background: '#297efa',
                                              border: 'none',
                                            }}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </form>
                                    ) : (
                                      <>
                                        <p
                                          className="text-white text-center mb-3"
                                          style={{ fontSize: '20px' }}
                                        >
                                          You've successfully redeemed your today's reward!
                                        </p>
                                        <p className="text-white text-center err-first-ctn">
                                          {couponGetxNumber != '' ? couponGetxNumber : ''}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Tasks Section */}
                              <div className=" px-2 my-3 bg-dark goat-posts py-3">
                                {taskData.length > 0 ? (
                                  taskData.map((data) => {
                                    return (
                                      <>
                                        <div className="goat-react-posts  posts-devopos-001  d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center justify-content-between ">
                                            <div className="">
                                              <img
                                                alt=""
                                                className="alt-goat-tabs"
                                                src={data.taskImgUrl}
                                              />
                                            </div>
                                            <div>
                                              <p className="mb-auto">
                                                <span className="text-white mb-5 goats-earn-01 fw-bold">
                                                  {data.taskTitle}
                                                </span>
                                                <br />
                                                <span
                                                  className=" fw-bold total-small-size-ctn"
                                                  style={{ color: 'grey' }}
                                                >
                                                  +{data.taskReward} $BANI
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div>
                                            {data.userTaskData.length ? (
                                              <>
                                                <span className="goat-check-01">
                                                  <BsCheckLg
                                                    className="text-white"
                                                    style={{ fontSize: '20px' }}
                                                  />
                                                </span>
                                              </>
                                            ) : loading[data._id] == true ? (
                                              <>
                                                <button className="goats-do-btn">
                                                  <Spinner animation="border" size="sm" />
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  onClick={() =>
                                                    doTaskSubmission(
                                                      data._id,
                                                      data.taskType,
                                                      data.taskDesc
                                                    )
                                                  }
                                                  className="goats-do-btn"
                                                >
                                                  DO
                                                </button>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <>
                                    <h2>There is no tasks</h2>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thirdtab;
