import React, { useEffect, useState } from 'react';
import Gameimg from '../asset/images/game-post.webp';
import Golddollar from '../asset/images/gold-dollar.gif';
import Silver from '../asset/images/silver-img.gif';
import Bronze from '../asset/images/bronze-img.gif';
import { makeApiRequest } from '../services/commonAxiosService';
import Banis from '../asset/images/BANI.png';

function Navtabs() {
  let userId = localStorage.getItem('userId') != undefined ? localStorage.getItem('userId') : '';
  const [userData, setUserData] = useState([]);
  const [gameLeaderboard, setGameLeaderboard] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loader, setLoader] = useState(false);
  const [userCounts, setUserCounts] = useState(0);
  const [activeTab, setActiveTab] = useState('wallOfFame');

  useEffect(() => {
    if (activeTab === 'wallOfFame') {
      getLeaderBoard();
    } else {
      getGameLeaderboard();
    }
  }, [activeTab]);

  const getLeaderBoard = async () => {
    try {
      setLoader(true);
      let payLoad = {
        chatId: userId,
      };
      let params = {
        url: 'users-rank',
        method: 'post',
        data: payLoad,
      };
      let response = await makeApiRequest(params);
      if (response.status) {
        setUserCounts(response.userCount);
        setUserData(response.rankData);
        setSelectedItem(response.userRankData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(true);
    }
  };

  const getGameLeaderboard = async () => {
    try {
      setLoader(true);
      let payLoad = {
        chatId: userId,
        limit: 50,
        page: 1,
      };
      let params = {
        url: 'game3-leaderboard',
        method: 'post',
        data: payLoad,
      };
      let response = await makeApiRequest(params);
      if (response.status) {
        setGameLeaderboard(response.data.leaderboard);
      }
      setLoader(false);
    } catch (error) {
      setLoader(true);
    }
  };

  let firstRank = userData[0];
  let secondRank = userData[1];
  let thirdRank = userData[2];

  const firstGameRank = gameLeaderboard[0];
  const secondGameRank = gameLeaderboard[1];
  const thirdGameRank = gameLeaderboard[2];

  return (
    <div className="goat-ranking-whl">
      <h5 className="Righteous-font"> {userCounts} Holders </h5>
      <ul className="nav nav-pills mb-3 py-2 goat-ranking-nav-tabs d-flex align-items-center justify-content-around">
        <li className="nav-item">
          <button
            className={`nav-link fw-bold text-white wall-of-fame-01 ${activeTab === 'wallOfFame' ? 'active' : ''}`}
            onClick={() => setActiveTab('wallOfFame')}
          >
            Wall of fame
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link fw-bold text-white wall-of-fame-01 ${activeTab === 'miniGame' ? 'active' : ''}`}
            onClick={() => setActiveTab('miniGame')}
          >
            Mini Game
          </button>
        </li>
      </ul>

      <div className="tab-content">
        {loader ? (
          <div className="position-absolute bani-loader-ctns-01">
            <img src={Banis} className="bani-loader-img" alt="loading" />
            <p className="mt-4 ms-1 text-white" style={{ fontSize: '15px' }}>
              <span className="dot text-white me-1">12</span>
              <span className="dot me-1">12</span>
              <span className="dot me-1">12</span>
            </p>
          </div>
        ) : activeTab === 'wallOfFame' ? (
          <div className="tab-pane fade show active">
            <div className="">
              {selectedItem && (
                <div className="d-flex bg-primary goat-codemychain-ctn my-4 py-3">
                  <span className="text-center" style={{ width: '100px' }}>
                    <img
                      alt=""
                      style={{ width: '50px' }}
                      src="https://dev.goatsbot.xyz/icons/face.gif?w=3840&amp;q=75"
                    />
                  </span>
                  <span>
                    <h2 className="fw-bold text-white goat-total-new-heading">
                      {selectedItem.username ? selectedItem.username : selectedItem.first_name}
                    </h2>
                    <span
                      className="fw-bold goat-total-sub-heading"
                      style={{ fontSize: '20px', color: 'rgb(142 156 169)' }}
                    >
                      {selectedItem.balance + ' $BANI' + ' , ' + '#' + selectedItem.leaderBoard}
                    </span>
                  </span>
                </div>
              )}

              <div className="my-3 goat-ranking-box-1">
                {firstRank && (
                  <div
                    className={`d-flex align-items-center py-3 justify-content-between ${firstRank.isHighlighted ? 'ranking-grade-points' : ''}`}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="initials-fallback-01 ms-2">
                          {firstRank.username
                            ? firstRank.username.charAt(0).toUpperCase()
                            : firstRank.first_name.charAt(0).toUpperCase()}
                        </div>
                      </div>
                      <p className="mb-0">
                        <span className="fw-bold goat-title-name goat-total-new-heading">
                          {firstRank.username ? firstRank.username : firstRank.first_name}
                        </span>
                        <br />
                        <span
                          className="fw-bold py-2 total-small-size-ctn"
                          style={{ color: '#686D72' }}
                        >
                          {firstRank.balance} $BANI
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="mb-auto">
                        <img alt="" style={{ width: '50px' }} src={Golddollar} />
                      </p>
                    </div>
                  </div>
                )}

                {secondRank && (
                  <div
                    className={`d-flex align-items-center py-3 justify-content-between ${secondRank.isHighlighted ? 'ranking-grade-points' : ''}`}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="initials-fallback-02 ms-2">
                          {secondRank.username
                            ? secondRank.username.charAt(0).toUpperCase()
                            : secondRank.first_name.charAt(0).toUpperCase()}
                        </div>
                      </div>
                      <p className="mb-0">
                        <span className="fw-bold goat-title-name goat-total-new-heading">
                          {secondRank.username ? secondRank.username : secondRank.first_name}
                        </span>
                        <br />
                        <span
                          className="fw-bold py-2 total-small-size-ctn"
                          style={{ color: '#686D72' }}
                        >
                          {secondRank.balance} $BANI
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="mb-auto">
                        <img alt="" style={{ width: '50px' }} src={Silver} />
                      </p>
                    </div>
                  </div>
                )}

                {thirdRank && (
                  <div
                    className={`d-flex align-items-center py-3 justify-content-between ${thirdRank.isHighlighted ? 'ranking-grade-points' : ''}`}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="initials-fallback-03 ms-2">
                          {thirdRank.username
                            ? thirdRank.username.charAt(0).toUpperCase()
                            : thirdRank.first_name.charAt(0).toUpperCase()}
                        </div>
                      </div>
                      <p className="mb-0">
                        <span className="fw-bold goat-title-name goat-total-new-heading">
                          {thirdRank.username ? thirdRank.username : thirdRank.first_name}
                        </span>
                        <br />
                        <span
                          className="py-2 fw-bold total-small-size-ctn"
                          style={{ color: '#686D72' }}
                        >
                          {thirdRank.balance} $BANI
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="mb-auto">
                        <img alt="" style={{ width: '50px' }} src={Bronze} />
                      </p>
                    </div>
                  </div>
                )}

                {userData.slice(3).map((data) => {
                  const initial = data.username
                    ? data.username.charAt(0).toUpperCase()
                    : data.first_name.charAt(0).toUpperCase();
                  return (
                    <div
                      key={data.id}
                      className={`d-flex align-items-center py-3 justify-content-between ${data.isHighlighted ? 'ranking-copper-medal' : ''}`}
                    >
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="initials-fallback ms-2">{initial}</div>
                        </div>
                        <p className="mb-0">
                          <span className="fw-bold goat-title-name goat-total-new-heading">
                            {data.username ? data.username : data.first_name}
                          </span>
                          <br />
                          <span
                            className="fw-bold py-2 total-small-size-ctn"
                            style={{ color: '#686D72' }}
                          >
                            {data.balance} $BANI
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-auto fw-bold goat-new-numbers">{'#' + data.leaderBoard}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="tab-pane fade show active">
            <div className="">
              {firstGameRank && (
                <div className="d-flex bg-primary goat-codemychain-ctn my-4 py-3">
                  <span className="text-center" style={{ width: '100px' }}>
                    <img
                      alt=""
                      style={{ width: '50px' }}
                      src="https://dev.goatsbot.xyz/icons/face.gif?w=3840&amp;q=75"
                    />
                  </span>
                  <span>
                    <h2 className="fw-bold text-white goat-total-new-heading">
                      {firstGameRank.username || firstGameRank.firstName}
                    </h2>
                    <span
                      className="fw-bold goat-total-sub-heading"
                      style={{ fontSize: '20px', color: 'rgb(142 156 169)' }}
                    >
                      Score: {firstGameRank.score}
                    </span>
                  </span>
                </div>
              )}

              <div className="my-3 goat-ranking-box-1">
                {gameLeaderboard.map((player, index) => (
                  <div
                    key={player.userChatId}
                    className={`d-flex align-items-center py-3 justify-content-between mb-3`}
                  >
                    <div className="d-flex align-items-center">
                      {player.userProfile ? (
                        <img
                          src={player.userProfile}
                          alt={player.firstName}
                          className="diamong-game"
                        />
                      ) : (
                        <div
                          className={`initials-fallback-${index < 3 ? '0' + (index + 1) : ''} ms-2`}
                        >
                          {(player.username || player.firstName).charAt(0).toUpperCase()}
                        </div>
                      )}
                      <p className="mb-0">
                        <span className="fw-bold goat-title-name goat-total-new-heading">
                          {player.username || player.firstName}
                        </span>
                        <br />
                        <span
                          className="fw-bold py-2 total-small-size-ctn"
                          style={{ color: '#686D72' }}
                        >
                          Score: {player.score}
                        </span>
                      </p>
                    </div>
                    <div>
                      {index === 0 && <img alt="Gold" style={{ width: '50px' }} src={Golddollar} />}
                      {index === 1 && <img alt="Silver" style={{ width: '50px' }} src={Silver} />}
                      {index === 2 && <img alt="Bronze" style={{ width: '50px' }} src={Bronze} />}
                      {index > 2 && (
                        <p className="mb-auto fw-bold goat-new-numbers">#{index + 1}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navtabs;
