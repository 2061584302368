import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { ImNotification } from 'react-icons/im';
import { makeApiRequest } from '../services/commonAxiosService';
import { ToastContainer, toast } from 'react-toastify';
import { FruitNinja } from '@cds.id/fruits-ninja';
import '@cds.id/fruits-ninja/style.css';
import 'react-toastify/dist/ReactToastify.css';

const appleJuice = '/fruits/apple-j.png';
const appleLeft = '/fruits/apple-l.png';
const appleRight = '/fruits/apple-r.png';
const appleSplat = '/fruits/apple-s.png';
const appleWhole = '/fruits/apple-w.png';
const peachJuice = '/fruits/peach-j.png';
const peachLeft = '/fruits/peach-l.png';
const peachRight = '/fruits/peach-r.png';
const peachSplat = '/fruits/peach-s.png';
const peachWhole = '/fruits/peach-w.png';
const sandiaJuice = '/fruits/sandia-j.png';
const sandiaLeft = '/fruits/sandia-l.png';
const sandiaRight = '/fruits/sandia-r.png';
const sandiaSplat = '/fruits/sandia-s.png';
const sandiaWhole = '/fruits/sandia-w.png';

export const LOCAL_FRUITS = [
  {
    name: 'apple',
    whole: appleWhole,
    left: appleLeft,
    right: appleRight,
    splat: appleSplat,
    juice: appleJuice,
    points: 10,
  },
  {
    name: 'peach',
    whole: peachWhole,
    left: peachLeft,
    right: peachRight,
    splat: peachSplat,
    juice: peachJuice,
    points: 30,
  },
  {
    name: 'sandia',
    whole: sandiaWhole,
    left: sandiaLeft,
    right: sandiaRight,
    splat: sandiaSplat,
    juice: sandiaJuice,
    points: 50,
  },
];
console.log('Use Local : ', LOCAL_FRUITS);
function NinjaBani() {
  const navigate = useNavigate();
  const [gameCount, setGameCount] = useState(false);
  const [gamePlayed, setGamePlayed] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  let userId = localStorage.getItem('userId') != undefined ? localStorage.getItem('userId') : '';

  useEffect(() => {
    getGameCount();
  }, []);

  const showToast = () => {
    toast.info('You can participate/play 5 times in a day!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'dark',
    });
  };

  const getGameCount = async () => {
    try {
      let payLoad = {
        gameType: 3,
        chatId: userId,
      };
      let data = {
        url: 'get-game-count',
        method: 'post',
        data: payLoad,
      };
      let response = await makeApiRequest(data);
      if (response.status) {
        setGameCount(true);
        setGamePlayed(response.game3);
      } else {
        setGameCount(false);
        setGamePlayed(response.game3);
      }
    } catch (error) {
      console.log('getGameCount err', error);
    }
  };

  const handleGameStart = () => {
    setIsPlaying(true);
  };

  const handleGameEnd = async (gameStats) => {
    try {
      setLoading(true);
      const rewardPoints = gameStats.score;

      let payLoad = {
        chatId: userId,
        gameType: 3,
        gameScore: rewardPoints,
      };
      let data = {
        url: 'game-count',
        method: 'post',
        data: payLoad,
      };

      let response = await makeApiRequest(data);
      if (response.status) {
        toast.success(`Game Over! You earned ${rewardPoints} $BANI!`, { theme: 'dark' });
      } else {
        toast.error('Failed to submit score', { theme: 'dark' });
      }

      // Return to menu after short delay
      setTimeout(() => {
        setIsPlaying(false);
        getGameCount();
      }, 2000);
    } catch (error) {
      console.log('handleGameEnd error', error);
      toast.error('Error submitting score', { theme: 'dark' });
      setIsPlaying(false);
    } finally {
      setLoading(false);
    }
  };

  if (isPlaying) {
    return (
      <div
        className="ninja-game-container"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#000',
        }}
      >
        <FruitNinja
          onGameEnd={handleGameEnd}
          maxLives={3}
          fruits={LOCAL_FRUITS}
          pointsPerSlice={10}
          initialDifficulty={1}
          className="w-100 h-100"
          spawnInterval={5000}
        />
      </div>
    );
  }

  return (
    <div className="min-vh-100 goat-full-box-02">
      <div className="container">
        <div className="d-flex align-items-center py-3">
          <Link to="/slotspage" className="me-auto">
            <AiOutlineArrowLeft className="text-white fs-4" />
          </Link>
          <span className="text-white text-center mx-auto">Ninja Bani</span>
          <div className="ms-auto">
            <ImNotification
              className="text-white fs-4"
              onClick={showToast}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>

        <div className="text-center text-white mt-4">
          <h2>Slice to Earn your $BANI</h2>
          <p>Games played today: {gamePlayed}/5</p>
          <p className="text-warning">X2 points until Xmas!</p>

          {gameCount && !loading ? (
            <button className="bani-new-play-btn mt-4" onClick={handleGameStart} disabled={loading}>
              Start Game
            </button>
          ) : (
            <div className="max-limit-01 mt-4">You have reached max limit</div>
          )}
        </div>

        {loading && (
          <div className="text-center mt-4">
            <div className="spinner-border text-white" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default NinjaBani;
